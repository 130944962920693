import { Image as ImageResponse } from '@/api/schemas';
import DummyImage from '@/assets/any/dummy.png';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { MultiUploadRef } from '@/components/icons/others';
import {
  StyledBoxHeight10,
  StyledFlexCenter,
  StyledGridGap5,
} from '@/components/styled';
import { useUploadImage } from '@/features/components/steps/BatchUpload/UploadRef/hooks/useUploadImage';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useBatchGetFiles } from '@/features/components/steps/BatchUpload/hooks/useGetBatchFiles';
import Image from 'next/image';
import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  StyledAfterButton,
  StyledUploadAfterInterface,
  StyledUploadInterface,
} from './styled';

type Props = {
  handleOpenDialog: () => void;
  targetId: string;
  targetNumber: number;
  disabled: boolean;
  warningFunction: WarningFunction;
  setRefApiResponse: (res: ImageResponse) => void;
};

export const UploadRefInterface = memo(
  ({
    handleOpenDialog,
    targetId,
    targetNumber,
    disabled,
    warningFunction,
    setRefApiResponse,
  }: Props): JSX.Element => {
    const {
      featureData,
      updateFeatureData,
      updateFeatureDataSingle,
      handleChangeCurrentSet,
    } = useBatchUpload();
    const { getFiles } = useBatchGetFiles({
      updateErrorAtGettingFiles: warningFunction.updateErrorAtGettingFiles,
    });
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
      useDropzone({
        getFilesFromEvent: (event) => getFiles(event),
      });

    useUploadImage({
      field: 'refImage',
      featureData,
      updateFeatureData,
      updateFeatureDataSingle,
      updateWarningKeyValue: warningFunction.updateWarningKeyValue,
      acceptedFiles,
      handleOpenDialog,
      setRefApiResponse,
    });

    const checkRefImage = useMemo(() => {
      if (!featureData) return false;
      if (!featureData.batch[targetNumber]) return false;
      if (!featureData.batch[targetNumber].refSam) return false;

      return (
        featureData?.batch[targetNumber].refSam.base64 ||
        featureData?.batch[targetNumber].refSam.combinedBase64
      );
    }, [targetNumber, featureData]);

    return (
      <>
        {checkRefImage ? (
          <StyledUploadAfterInterface
            key={`ref${targetNumber}-exist-dropzone`}
            {...(disabled ? {} : getRootProps())}
            onClick={() => {
              handleChangeCurrentSet(targetId);
              warningFunction.resetWarnings();
            }}
          >
            <input {...(disabled ? { type: 'hidden' } : getInputProps())} />
            <Box
              sx={{
                position: 'relative',
                width: 240,
                height: 240,
                borderRadius: '4px',
                border: isDragActive
                  ? `1px solid ${'var(--color-primary-main)'}`
                  : '1px solid #ccc',
                backgroundColor: '#fff',
                '&:hover': {
                  border: disabled
                    ? '1px solid #ccc'
                    : `1px solid ${'var(--color-primary-main)'}`,
                  cursor: disabled ? 'default' : 'pointer',
                },
              }}
              // biome-ignore lint/suspicious/noEmptyBlockStatements:
              onClick={disabled ? () => {} : handleOpenDialog}
            >
              {/* base64 or param.currentRef */}
              <Image
                src={
                  featureData?.batch[targetNumber].refSam.combinedBase64
                    ? featureData.batch[targetNumber].refSam.combinedBase64
                    : featureData?.batch[targetNumber].refSam.base64
                    ? featureData.batch[targetNumber].refSam.base64
                    : DummyImage
                }
                fill
                alt="pict"
                quality={90}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledAfterButton {...getRootProps()}>
                <input {...getInputProps()} />

                <Button
                  mode="border"
                  width={154}
                  height={30}
                  style={{
                    fontSize: 13,
                    backgroundColor: isDragActive
                      ? `${'var(--color-gray-light)'}`
                      : `#fff`,
                    border: '1px solid #ccc',
                  }}
                  key={`ref${targetNumber}-exist-button`}
                  disabled={disabled}
                >
                  <Box>参照画像を差し替える</Box>
                </Button>
              </StyledAfterButton>
            </Box>
          </StyledUploadAfterInterface>
        ) : (
          <StyledUploadInterface {...(disabled ? {} : getRootProps())}>
            <input {...(disabled ? { type: 'hidden' } : getInputProps())} />
            <Box
              style={{
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                width: 240,
                height: 240,
              }}
              onClick={() => {
                disabled && handleChangeCurrentSet(targetId);
                warningFunction.resetWarnings();
              }}
            >
              <Box style={{ fontSize: 20, fontWeight: 500 }}>
                <StyledGridGap5 style={{ justifyContent: 'center' }}>
                  <StyledFlexCenter>
                    <MultiUploadRef />
                  </StyledFlexCenter>
                  参照する画像を
                  <br />
                  ドラック＆ドロップ
                  <Box style={{ fontSize: 16, color: '#999' }}>1枚の画像</Box>
                </StyledGridGap5>
                <StyledBoxHeight10 />
                <Button style={{ fontSize: 15 }} disabled={disabled}>
                  ファイルを選択
                </Button>
              </Box>
            </Box>
          </StyledUploadInterface>
        )}
      </>
    );
  },
);
