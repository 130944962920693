import { Dialog } from '@/components/molecules/Dialog';
import { PopupDownloadFormat } from '@/features/components/modeless/PopupDownloadFormat';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useState } from 'react';
import { BatchDownloadContent } from './BatchDownloadContent';
import { ColorTemperatureContent } from './ColorTemperatureContent';
import { SingleDownloadContent } from './SingleDownloadContent';
import { useGetTaskApi } from './hooks/useGetTaskApi';
import { useHandlers } from './hooks/useHandlers';
import { useInitializeDownloadDialog } from './hooks/useInitializeDownloadDialog';
import { useSupport } from './hooks/useSupport';

type Props = {
  taskId: string;
  isOpenDownloadDialog: boolean;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
  targetModeEn?: string;
  targetModeJp?: string;
};

export type DownloadDialogMode = 'selectDownloadImage' | 'selectSupportImage' | 'supportForm'

export const DownloadDialog = memo(
  ({
    taskId,
    isOpenDownloadDialog,
    handleCloseDownloadDialog,
    refetchTasks,
    targetModeEn,
    targetModeJp,
  }: Props): JSX.Element => {
    const { taskApiResponse } = useGetTaskApi({ taskId });

    const {
      // handleCloseDialogWithPopper,
      handleOpenPopper,
      handleClosePopper,
      isOpenPopper,
      anchorElement,
      checkedCount,
    } = useHandlers();

    const { hasPsd, isNoBackground } = useInitializeDownloadDialog({
      taskApiResponse,
    });
    const [downloadDialogMode, setDownloadDialogMode] = useState<DownloadDialogMode>('selectDownloadImage')

    const {
      handleClickSupportStartButton,
      handleClickSupportCancelButton,
      handleClickApplySupportWithSelectImageButton
    } = useSupport({
      setDownloadDialogMode
    });

    return (
      <>
        {taskId && taskApiResponse && targetModeJp && (
          <Dialog
            isOpenDialog={isOpenDownloadDialog}
            onClose={handleCloseDownloadDialog}
            maxWidth="xl"
            dialogTitle=""
            hasCloseIcon
          >
            {targetModeEn === 'colorTemperature' ? (
              <ColorTemperatureContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
                downloadDialogMode={downloadDialogMode}
                handleClickSupportStartButton={handleClickSupportStartButton}
                handleClickSupportCancelButton={handleClickSupportCancelButton}
                handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                targetModeEn={targetModeEn}
                handleCloseDownloadDialog={handleCloseDownloadDialog}
                refetchTasks={refetchTasks}
              />
            ) : targetModeEn === 'backgroundLora' ||
              targetModeEn === 'multiWhite' ? (
              <BatchDownloadContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
                downloadDialogMode={downloadDialogMode}
                handleClickSupportStartButton={handleClickSupportStartButton}
                handleClickSupportCancelButton={handleClickSupportCancelButton}
                handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                targetModeEn={targetModeEn}
                handleCloseDownloadDialog={handleCloseDownloadDialog}
                refetchTasks={refetchTasks}
              />
            ) : (
              <SingleDownloadContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
                downloadDialogMode={downloadDialogMode}
                handleClickSupportStartButton={handleClickSupportStartButton}
                handleClickSupportCancelButton={handleClickSupportCancelButton}
                handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                targetModeEn={targetModeEn}
                handleCloseDownloadDialog={handleCloseDownloadDialog}
                refetchTasks={refetchTasks}
              />
            )}
          </Dialog>
        )}
        <PopupDownloadFormat
          popperTitle="出力形式の選択"
          popperPlacement="bottom"
          anchorElement={anchorElement}
          isOpenPopper={isOpenPopper}
          handleClosePopper={handleClosePopper}
          taskApiResponse={taskApiResponse}
          refetchTasks={refetchTasks}
          /* モーダルにポップアップを表示すると影が分かりづらいため濃くする */
          boxShadow="0px 0px 20px 0px rgba(0,0,0,0.5)"
          isNoBackground={isNoBackground}
          hasPsd={hasPsd}
        />
      </>
    );
  },
);
