import { Popper } from '@/components/molecules/Popper';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { TabButton } from '@/features/components/atoms/TabButton/TabButton';
import { ResultDisplayName } from '@/features/components/functions/ResultDisplayName';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { PopupColor } from '@/features/components/modeless/PopupColor';
import { memo } from 'react';
import { useTargetTypeHandlers } from '../hooks/useTargetTypeHandlers';
import { ResultBox } from './ResultBox';
import {
  StyledTabButtonList,
  StyledTabButtonListChild,
  StyledTitle,
} from './styled';

type Props = {
  handleClickFeatureTargetType: (target: string) => void;
  handleResetAfterPreview: () => void;
  isDisabled: boolean;
};
export const TargetTypeForColor = memo(
  ({
    handleClickFeatureTargetType,
    handleResetAfterPreview,
    isDisabled,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});
    const {
      isOpenPopper,
      handleOpenPopper,
      handleClosePopper,
      popperAnchorRef,
      isOpenDialog,
      handleOpenDialog,
      handleCloseDialog,
      handleChangeNewColor,
      handleChangeCurrentColor,
      handleSubmit,
    } = useTargetTypeHandlers({ handleResetAfterPreview });

    return (
      <>
        <i ref={popperAnchorRef} />
        <StyledTitle>カラーのタイプ</StyledTitle>
        <StyledTabButtonList>
          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handleClickFeatureTargetType('color');
              }}
              label="単色"
              isActive={featureData?.single?.param?.targetType === 'color'}
              isDisabled={isDisabled}
            />
          </StyledTabButtonListChild>
          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handleClickFeatureTargetType('image');
              }}
              label="画像"
              isActive={featureData?.single?.param?.targetType === 'image'}
              isDisabled={isDisabled}
            />
          </StyledTabButtonListChild>
        </StyledTabButtonList>
        <ResultBox
          handleOpenPopper={handleOpenPopper}
          handleOpenDialog={handleOpenDialog}
          isDisabled={isDisabled}
        />
        {featureData?.single?.param?.targetType === 'color' &&
          featureData?.single?.param?.currentColor && (
            <ResultDisplayName
              displayName={featureData.single.param.currentColor}
            />
          )}
        {featureData?.single?.param?.targetType === 'image' &&
          featureData?.single?.param?.refImageName && (
            <ResultDisplayName
              displayName={featureData.single.param.refImageName}
            />
          )}

        <Popper
          title={
            featureData?.single?.param?.targetType === 'color'
              ? 'カラーを選択'
              : '画像の選択'
          }
          isOpenPopper={isOpenPopper}
          onClose={handleClosePopper}
          anchorElement={popperAnchorRef.current}
          placementY={12}
          paddingRight="297px"
          placement="top-end"
        >
          {featureData?.single?.param?.targetType === 'color' && (
            <PopupColor
              newColor={featureData.single.param.newColor}
              currentColor={featureData.single.param.currentColor}
              isOpenColorPicker
              onChangeNewColor={handleChangeNewColor}
              onChangeCurrentColor={handleChangeCurrentColor}
              onClose={handleClosePopper}
            />
          )}
        </Popper>
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle="参照する画像をアップロード"
          dialogExplain="カラーを参照する画像をアップロードし、参照範囲を指定してください"
        />
      </>
    );
  },
);
